import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Link, AppBar, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import NotificationsPopover from './NotificationsPopover';

import { ShopProductSearch }from '../../../sections/@dashboard/e-commerce/shop'
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur({color: theme.palette.background.neutral,opacity: theme.palette.glassOpacity}),
  top:8,
  borderRadius:5,
  marginRight:8,
  marginLeft:8,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 16}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH+24}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { themeMode, onChangeMode } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const changeMode =()=>{
    if(themeMode === 'light'){
      onChangeMode('dark')
    }else{
      onChangeMode('light')
    }
  }

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <ShopProductSearch/>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" >
          
         <IconButtonAnimate  sx={{  color: 'text.primary' }}>
          <Link to={`/track-order`} underline="none"  color="inherit" component={RouterLink}>
          <Iconify icon="clarity:map-marker-solid"  width={20} height={20} />
          </Link> 
         </IconButtonAnimate>

          <NotificationsPopover />

          <IconButtonAnimate onClick={changeMode} sx={{ color: 'text.primary' }}>
            <Iconify icon={themeMode === 'light' ? 'ph:moon-duotone':'ph:sun-duotone'} width={20} height={20} />
          </IconButtonAnimate>

        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
