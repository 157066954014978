// routes
import { PATH_DASHBOARD, PATH_INSTAGRAM, PATH_LINKEDIN, PATH_WHATSAPP } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  shop: getIcon('ic_shop'),
  booking: getIcon('ic_booking'),
  policy: getIcon('ic_policy'),
  tac: getIcon('ic_tac'),
  contact: getIcon('ic_contact'),
  about: getIcon('ic_about'),
  instagram: getIcon('ic_instagram'),
  linkedin: getIcon('ic_linkedin'),
  whatsapp: getIcon('ic_whatsapp'),
  track :getIcon('ic_track')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'home', path: PATH_DASHBOARD.eCommerce.home, icon: ICONS.shop },
      { title: 'Cart', path: PATH_DASHBOARD.eCommerce.checkout, icon: ICONS.cart },
      { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice, icon: ICONS.invoice},
      { title: 'track order', path: PATH_DASHBOARD.eCommerce.trackOrder, icon: ICONS.track},
    ],
  },

  // Customer Care
  // ----------------------------------------------------------------------
  {
    subheader: 'Customer Care',
    items: [
      {
        title: 'Policies',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.policy,
        children: [
          { title: 'Return Policy', path: PATH_DASHBOARD.eCommerce.returnPolicy, icon: ICONS.shop },
          { title: 'Shipping Policy', path: PATH_DASHBOARD.eCommerce.shipingPolicy, icon: ICONS.cart },
          { title: 'Privacy Policy', path: PATH_DASHBOARD.eCommerce.privacyPolicy, icon: ICONS.invoice},
         ],
      },
      {
        title: 'Terms & Conditions',
        path: PATH_DASHBOARD.eCommerce.tac,
        icon: ICONS.tac,
      },
      {
        title: 'Contact Us',
        path: PATH_DASHBOARD.eCommerce.contactUs,
        icon: ICONS.contact,
      },
      {
        title: 'About Us',
        path: PATH_DASHBOARD.eCommerce.aboutUs,
        icon: ICONS.about,
      },
    ]
  },

  
  // Connect
  // ----------------------------------------------------------------------
  {
    subheader: 'Connect',
    items: [
      { title: 'Instagram', path: PATH_INSTAGRAM, icon: ICONS.instagram },
      { title: 'LinkedIn', path: PATH_LINKEDIN, icon: ICONS.linkedin },
      { title: 'Whatsapp', path: PATH_WHATSAPP, icon: ICONS.whatsapp},
    ],
  },
];

export default navConfig;
