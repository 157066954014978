import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Image from '../../../../components/Image';

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, variant, isNewArrival } = product;

  const linkTo = `/product/${paramCase(name)}`;

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) { 
      try {
        document.documentElement.requestFullscreen();
      } catch (error) {
        console.log()
      }
    } 
  };

  return (
    <Card onClick={toggleFullScreen}>
       <Link to={linkTo} underline="none"  color="inherit" component={RouterLink}>
      <Box sx={{ position: 'relative' }}>
        {isNewArrival && (
          <Label
            variant="filled"
            color={'info'}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            new
          </Label>
        )}
        <Image alt={name} src={cover} ratio="1/1" />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          
          <Stack direction="row" spacing={0.5} alignItems="flex-end">
           <Typography variant="h6">
          <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through', fontSize:12 }}>
          ₹{fCurrency(variant[0].mrp)}
          </Box>
          &nbsp;₹{fCurrency(variant[0].price)}
         </Typography>
          </Stack>
        </Stack>
      </Stack>
      </Link>
    </Card>
  );
}
