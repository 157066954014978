import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
          path: '*',
          element: (
              <DashboardLayout />
          ),
          children: [
            { element: <Navigate to="/home" replace />, index: true },
            { path: 'home', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
            { path: 'track-order', element: <EcommerceTrackOrder /> },
            { path: 'about-us', element: <AboutUs /> },
            { path: 'contact-us', element: <ContactUs /> },
            { path: 'terms-and-conditions', element: <TAC /> },
            { path: 'return-policy', element: <ReturnPolicy /> },
            { path: 'shipping-policy', element: <ShipingPolicy /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
          ],
        },
      ],
);
}

// IMPORT COMPONENTS

const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const EcommerceTrackOrder = Loadable(lazy(() => import('../pages/dashboard/EcommerceTrackOrder')));
const AboutUs = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const ContactUs = Loadable(lazy(() => import('../pages/Contact')));
const TAC = Loadable(lazy(() => import('../pages/dashboard/Tac')));
const ReturnPolicy = Loadable(lazy(() => import('../pages/dashboard/PolicyReturn')));
const ShipingPolicy = Loadable(lazy(() => import('../pages/dashboard/PolicyShiping')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/dashboard/PolicyPrivacy')));


